import React,{useEffect,useState} from 'react';
import "./MobileSignUp.css";
import supportLogo from "../../../img/signup/headset-signup.png";
import emailLogo from "../../../img/signup/email-signup.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import successLogo from "../../../icons/Success-Image.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPhoneNumber,
  selectSaveData,
  signUpAsync,
} from "../../../features/auth/authSlice";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import MobileContactInfo from './MobileContactInfo';
import MobileBasicInfo from './MobileBasicInfo';
import MobileBusinessPAN from './MobileBusinessPAN';
import MobileBusinessRegistration from './MobileBusinessRegistration';
import MobileBusinessOwner from './MobileBusinessOwner';
import MobileUploadDocument from './MobileUploadDocument';
import MobileSignUpSideBar from './MobileSignUpSideBar';

function MobileSignUp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const saveData = useSelector(selectSaveData);
    const [emailVerify, setEmailVerify] = useState((saveData && saveData?.Email)? true :false);
    const [phoneVerify, setPhoneVerify] = useState(true);
    const [PANVerify, setPANVerify] = useState(false);
    const [GSTVerify, setGSTVerify] = useState(false);
    const [aadharVerify, setAadharVerify] = useState(false);
    const [herebyDeclare, setHerebyDeclare] = useState(false);
    const [complete, setComplete] = useState(false);
    const phoneNumber = useSelector(selectPhoneNumber);
    const [currentStep, setCurrentStep] = useState(0);
    const [tempEmail, setTempEmail] = useState((saveData && saveData?.Email) || null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
  
  
    const [finalData, setFinalData] = useState({
      Email: (saveData && saveData?.Email) || null,
      PhoneNumber: (phoneNumber && phoneNumber) || null,
      ReceiveWhatsApp: (saveData && saveData?.ReceiveWhatsApp) ||false,
      FirstName: (saveData && saveData?.FirstName) || null,
      LastName: (saveData && saveData?.LastName) || null,
      Gender: null,
      DOB: (saveData && saveData?.DOB) || null,
      USER_IMAGE: (saveData && saveData?.USER_IMAGE) || null,
      pin_code: (saveData && saveData?.pin_code) || null,
      location: (saveData && saveData?.location) || null,
      district: (saveData && saveData?.district) || null,
      state_id: (saveData && saveData?.state_id) || null,
      address_line_1: (saveData && saveData?.address_line_1) || null,
      address_line_2: (saveData && saveData?.address_line_2) || null,
      save_address: (saveData && saveData?.save_address) || "Dealership",
      Password: "123",
      pan_card: (saveData && saveData?.pan_card) || null,
      Have_GSTIN: (saveData && saveData?.Have_GSTIN) || "yes",
      business_name: (saveData && saveData?.business_name) || null,
      applicable: (saveData && saveData?.applicable) || "1",
      aadhar: (saveData && saveData?.aadhar) || null,
      GST: (saveData && saveData?.GST) || null,
      business_document: (saveData && saveData?.business_document) || "Tax Return",
      Document_IMAGE: (saveData && saveData?.Document_IMAGE) || null,
      GST_DATA: null,
      Aadhar_DATA: null,
      PAN_DATA: null,
    });
  
    useEffect(()=>{
      if((saveData && saveData?.Email) && (saveData?.Email === finalData?.Email)){
        setEmailVerify(true);
      }
      else{
        setEmailVerify(false);
      }
    },[saveData,finalData]);
  
    // Validation states for required fields
    const fieldRequired = [
      ["Email", "PhoneNumber", "emailVerify", "phoneVerify", "ReceiveWhatsApp"],
      [
        "FirstName",
        "LastName",
        "Gender",
        "DOB",
        "pin_code",
        "address_line_1",
        "location",
        "district",
        "state_id",
        "save_address",
      ],
      ["pan_card", "PANVerify"],
      ["Have_GSTIN", "business_name", "applicable"],
      ["aadhar", "aadharVerify", "herebyDeclare"],
      ["business_document", "document_IMAGE"],
    ];
  
    const updateFinalData = (dataToUpdate) => {
      setFinalData((prevData) => ({
        ...prevData,
        ...dataToUpdate,
      }));
    };
    const steps = [
      {
        s: 0,
        name: "Contact Information",
        component: (
          <MobileContactInfo
            updateFinalData={updateFinalData}
            finalData={finalData}
            emailVerify={emailVerify}
            setEmailVerify={setEmailVerify}
            phoneVerify={phoneVerify}
            setPhoneVerify={setPhoneVerify}
            tempEmail={tempEmail}
            setTempEmail={setTempEmail}
          />
        ),
      },
      {
        s: 1,
        name: "Personal & Shipping Details",
        component: (
          <MobileBasicInfo updateFinalData={updateFinalData} finalData={finalData} />
        ),
      },
      {
        s: 2,
        name: "Business PAN",
        component: (
          <MobileBusinessPAN
            updateFinalData={updateFinalData}
            finalData={finalData}
            PANVerify={PANVerify}
            setPANVerify={setPANVerify}
          />
        ),
      },
      {
        s: 3,
        name: "Business Registration Details",
        component: (
          <MobileBusinessRegistration
            updateFinalData={updateFinalData}
            finalData={finalData}
            GSTVerify={GSTVerify}
            setGSTVerify={setGSTVerify}
          />
        ),
      },
      {
        s: 4,
        name: "Business Owner Details",
        component: (
          <MobileBusinessOwner
            updateFinalData={updateFinalData}
            finalData={finalData}
            aadharVerify={aadharVerify}
            setAadharVerify={setAadharVerify}
            setHerebyDeclare={setHerebyDeclare}
            herebyDeclare={herebyDeclare}
          />
        ),
      },
      // {
      //   s: 5,
      //   name: "Upload Business Document",
      //   component: (
      //     <MobileUploadDocument
      //       updateFinalData={updateFinalData}
      //       finalData={finalData}
      //     />
      //   ),
      // },
    ];
  
    // Function to check if all required fields of the current step are filled
    const isStepValid = () => {
      const fields = fieldRequired[currentStep];
      // console.log(fields);
      for (const key in fields) {
        if (finalData.hasOwnProperty(fields[key])) {
          // console.log(fields[key], finalData[fields[key]]);
          if (finalData[fields[key]] === null) {
            return false;
          }
        } else {
          if (
            (fields[key] === "emailVerify" && !emailVerify) ||
            (fields[key] === "phoneVerify" && !phoneVerify) ||
            (fields[key] === "PANVerify" && !PANVerify) ||
            (fields[key] === "GSTVerify" && !GSTVerify) ||
            (fields[key] === "aadharVerify" && !aadharVerify) ||
            (fields[key] === "herebyDeclare" && !herebyDeclare)
          ) {
            return false;
          }
        }
      }
      return true;
    };
  
    const validData = async() => {
      // const fields = fieldRequired[currentStep];
      // console.log(fields);
      // const newData = [];
      // for (const key in fields) {
      //   if (finalData.hasOwnProperty(fields[key])) {
      //     console.log(fields[key], finalData[fields[key]]);
      //     if (finalData[fields[key]] !== null) {
      //       newData.push({ [fields[key]]: finalData[fields[key]] });
      //     }
      //   }
      // }
      // await setValidSave([...validSave, ...newData]);
      const sendData = {
        phoneNumber: finalData?.PhoneNumber || "",
        updatedAttributes: {
          FirstName: finalData?.FirstName || "",
          LastName: finalData?.LastName || "",
          Email: finalData?.Email || "",
          Gender: finalData?.Gender || "",
          DOB: finalData?.DOB || "",
          ReceiveWhatsApp: finalData?.ReceiveWhatsApp || false,
          business_name: finalData?.business_name || "",
          address_line_1: finalData?.address_line_1 || "",
          address_line_2: finalData?.address_line_2 || "",
          pin_code: finalData?.pin_code || "",
          location: finalData?.location || "",
          district: finalData?.district || "",
          save_address: finalData?.save_address || "Dealership",
          state_id: finalData?.state_id || 0,
          GST: finalData?.GST || "",
          aadhar: finalData?.aadhar || "",
          pan_card: finalData?.pan_card || "",
          Have_GSTIN: finalData?.Have_GSTIN || "yes",
          applicable: finalData?.applicable || "1"
        }
      };
      // console.log(sendData);
      try {
        // Call the API
        const response = await fetch(`${API_BASE_URL}cache_data`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sendData),
        });
  
        // console.log(response)
        if (response.ok) {
          // console.log("Cache data API call successful");
        } else {
          console.error("Cache data API call failed");
        }
      } catch (error) {
        console.error("Error calling cache data API:", error);
      }
    };
  
    const handleCancel = (e) => {
      e.preventDefault();
      navigate("/");
    };
    const handleBack = () => {
      if (currentStep === 0) {
        return;
      }
      setCurrentStep(currentStep - 1);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log("Enter submit");
      // console.log(e);
      // console.log(finalData);
      if (e.key === "Enter") {
        return;
      }
      // Check if the form is valid and navigate accordingly
      if (currentStep < steps.length - 1 && isStepValid()) {
        await validData();
        setCurrentStep(currentStep + 1);
        return;
      }
      if (currentStep === steps.length - 1 && isStepValid()) {
        handleOpen();
        try {
          const response = await dispatch(signUpAsync(finalData));
  
          console.log(response);
          if (response?.payload) {
            // If the response is successful (status 200-299)
              handleClose();
              setComplete(true);
              return;
          } else {
            handleClose();
            Swal.fire({
              title: "Signup failed",
              text: "Failed to SignUp.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
  
        } catch (error) {
          handleClose();
          console.log("Error signing up:", error);
          // Close the loading modal
          handleClose();
  
          // Show an error message using SweetAlert if the sign-up fails
          Swal.fire({
            title: "Signup Failed",
            text: `An error occurred: ${error.message || "Please try again later."}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    };
  
    const handleNavigate = () => {
      navigate("/");
    };
  return (
    <>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <form className="mobile-sign-up-form" onSubmit={handleSubmit}>
      <div className="mobile-sign-up-container">
        <div className="mobile-sign-up-main-container">
          <div className="mobile-sign-up-sidebar-container">
            <MobileSignUpSideBar currentStep={currentStep} steps={steps} />
          </div>
          <div className="mobile-sign-up-each-step-container">
            {currentStep > 0 && (
              <div
                className="mobile-sign-up-back-btn-container"
                onClick={handleBack}
              >
                <ArrowBackIosIcon />
              </div>
            )}
             
            <div className="mobile-sign-up-each-step-main-container">
              {steps[currentStep].component}
            </div>
          </div>
          <div className="mobile-sign-up-buttons-container">
            <span
              className="mobile-sign-up-cancel-btn"
              onClick={(e) => handleCancel(e)}
            >
              Cancel
            </span>
            <button
              className="mobile-sign-up-continue-btn"
              style={{
                background:
                  (currentStep === steps.length - 1 || isStepValid()) &&
                  "var(--color-green)",
                color:
                  (currentStep === steps.length - 1 || isStepValid()) &&
                  "white",
              }}
            >
              {currentStep === steps.length - 1
                ? "Submit"
                : steps[currentStep + 1].name}
              <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
    </form>
    {complete && <div className="mobile-signup-overlay"></div>}
    {complete && (
      <div className="mobile-signup-onboarding-complete-container">
        <img src={successLogo} alt="" className="mobile-signup-onboarding-img" />
        <span className="mobile-signup-congratulation-text">Congratulations!</span>
        <span className="mobile-signup-congratulation-desc">
          You have successfully created your account
        </span>
        <span
          className="mobile-signup-continue-shopping-btn"
          onClick={handleNavigate}
        >
          Continue to shopping
        </span>
      </div>
    )}
  </>
  )
}

export default MobileSignUp