import React, { useState } from "react";
import "./MobileAdminDashboard.css";
import { useNavigate, useParams } from "react-router";
import MobileAdminPreOrder from "./MobileAdminPreOrder";
import MobileAdminOrders from "./MobileAdminOrders";
import MobileAdminCarts from "./MobileAdminCarts";
import MobileAdminToggle from "./MobileAdminToggle";
import MobileAdminShipping from "./MobileAdminShipping";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import {
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";

function MobileAdminDashboard() {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [nav, setNav] = useState("pre-orders");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderContent = () => {
    switch (nav) {
      case "pre-orders":
        return <MobileAdminPreOrder />;
      case "orders":
        return <MobileAdminOrders />;
      case "carts":
        return <MobileAdminCarts />;
      case "toggle":
        return <MobileAdminToggle />;
      case "shipping":
        return <MobileAdminShipping />;
      default:
        return <MobileAdminPreOrder />;
    }
  };
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="mobile-admin-dashboard-v2-container">
      <AppBar position="fixed" className="mobile-admin-dashboard-header-v2">
        <Toolbar className="mobile-admin-dashboard-header-v2">
          <img
            src={cyclecircleLogo}
            alt="cc-logo"
            className="mobile-admin-dashboard-cc-logo-v2"
            onClick={() => navigate("/")}
          />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className="hamburger-menu-icon"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (Sidebar) */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        sx={{ "& .MuiDrawer-paper": { width: "55vw" } }}
        className="mobile-admin-drawer-v2"
      >
        <div className="sidebar-header">
          <Avatar sx={{ width: 56, height: 56, margin: "5px auto" }}>
            <img
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                userDetail?.UserImage || ""
              }`}
              alt={userDetail?.UserImage || ""}
              style={{ width: "100%" }}
            />
          </Avatar>
          <h3 style={{ textAlign: "center", margin: "5px 0" }}>
            {userDetail?.FirstName}&nbsp;{userDetail?.LastName}
          </h3>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setNav("pre-orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "pre-orders" ? "var(--color-blue)" : "inherit",
              color: nav === "pre-orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "pre-orders" ? "white" : "inherit",
              }}
            >
              <WorkHistoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Pre-Orders"
              className="list-item-text-mobile"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "orders" ? "var(--color-blue)" : "inherit",
              color: nav === "orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "orders" ? "white" : "inherit",
              }}
            >
              <Inventory2OutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" className="list-item-text-mobile" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("carts");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "carts" ? "var(--color-blue)" : "inherit",
              color: nav === "carts" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "carts" ? "white" : "inherit",
              }}
            >
              <ShoppingCartOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Carts" className="list-item-text-mobile" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("toggle");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "toggle" ? "var(--color-blue)" : "inherit",
              color: nav === "toggle" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "toggle" ? "white" : "inherit",
              }}
            >
              <ToggleOnOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Switch to Admin"
              className="list-item-text-mobile"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("shipping");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "shipping" ? "var(--color-blue)" : "inherit",
              color: nav === "shipping" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "shipping" ? "white" : "inherit",
              }}
            >
              <LocalShippingOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Free Shipping"
              className="list-item-text-mobile"
            />
          </ListItem>
        </List>
      </Drawer>

      <div className="mobile-admin-dashboard-content">
        <h3 className="mobile-admin-dashboard-content-heading">Admin {nav}</h3>
        {renderContent()}
      </div>
    </div>
  );
}

export default MobileAdminDashboard;
