import React, { useEffect, useState } from "react";
import "./MobileCategory.css";
import verticalIcon from "../../img/mobileIcon/Button-Category.png";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import {
  fetchAllNavigationsAsync,
  selectAllNavigations,
} from "../../features/HomePage/homeSlice";
import { IMAGE_BASE } from "../../config";
import { useNavigate } from "react-router";
import MobileBigNavigation from "./MobileBigNavigation";

function MobileCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [combinedCategories, setCombinedCategories] = useState([]);
  const [showNavigation, setShowNavigation] = useState(null);
  const verticalDetail = useSelector(selectAllNavigations);
  useEffect(() => {
    const fetchCategories = async () => {
      const responses = await Promise.all([
        dispatch(fetchAllNavigationsAsync(1)),
        dispatch(fetchAllNavigationsAsync(2)),
        dispatch(fetchAllNavigationsAsync(3)),
      ]);

      // Combine all categories from the fetched responses into a single array
      const allCategories = responses.reduce((acc, response) => {
        if (response.payload?.categories) {
          return [...acc, ...response.payload.categories];
        }
        return acc;
      }, []);

      setCombinedCategories(allCategories);
    };

    fetchCategories();
  }, [dispatch]);

  const handelCategoryNavigate = (catID, catName) => {
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    // dispatch(fetchAllCategoryProductsAsync(catID));
  };

  return (
    <section className="mobile-category-v2-container">
      <div className="mobile-category-v2-left-category-icon" onClick={() => setShowNavigation(true)}>
        <img
          src={verticalIcon}
          alt="category-icon"
          className="mobile-category-v2-category-icon"
          
        />
        <span className="mobile-category-v2-category-text">Categories</span>
      </div>
      {showNavigation && <MobileBigNavigation setShowNavigation={setShowNavigation}/>}
      <div className="mobile-category-v2-all-category-list-container" style={{display:showNavigation ? "none":""}}>
        {combinedCategories.length > 0 &&
          combinedCategories.map((category, index) => (
            <div
              className="mobile-category-v2-each-category-container"
              key={index}
              onClick={() =>
                handelCategoryNavigate(
                  category?.CategoryID,
                  category?.categoryName
                )
              }
            >
              <img
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
                  category?.image && category?.image
                }`}
                alt="category-img"
                className="mobile-category-v2-each-category-img"
              />
              <span className="mobile-category-v2-each-category-name">
                {category.categoryName && category?.categoryName}
              </span>
            </div>
          ))}
      </div>
    </section>
  );
}

export default MobileCategory;
