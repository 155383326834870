import React, { useEffect, useState } from "react";
import "./MobileAdminPreOrder.css";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import NoCartItem from "../../components/Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
  formatWithCommasWithoutDecimal,
} from "../../utils/utils";
import {
  fetchAllAdminUsersAsync,
  fetchAllAdminUsersOrdersAsync,
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllAdminUser,
  selectAllAdminUserOrders,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";

function MobileAdminOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUser);
  const userOrders = useSelector(selectAllAdminUserOrders);
  // console.log(users, userOrders);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [openOrderProduct, setOpenOrderProduct] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    // console.log(searchQuery);
    if (users) {
      setFilteredUsers(
        users.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "order_count") {
        sortedUsers.sort((a, b) => b?.OrderCount - a?.OrderCount);
      } else if (sortCriteria === "state_name") {
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  // console.log("FilteredUser",filteredUsers);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllAdminUsersOrdersAsync(UserID)).then(() => {
      handleClose();
    });
  };

  const calculateTotalOrder = () => {
    let totalOrder = 0;
    for (const order of users) {
      totalOrder += order?.OrderCount;
    }
    return totalOrder;
  };

  const handleDownloadInvoice = async (id) => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${
        id && id
      }.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="mobile-admin-preorder-v2-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-admin-dashboard-middle-container">
        <div className="mobile-admin-dashboard-middle-search-container">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by name, phone or state"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mobile-admin-dashboard-search-input"
          />
        </div>
        <div className="mobile-admin-dashboard-sort-by-container">
          <div className="mobile-admin-dashboard-total-preorder-container">
            <span className="mobile-admin-dashboard-heading-total">
              Total Orders
            </span>
            <span className="mobile-admin-dashboard-total-count">
              {users && Array?.isArray(users) ? calculateTotalOrder() : 0}
            </span>
            <Inventory2OutlinedIcon />
          </div>
          <div className="admin-dashboard-sort-by">
            <span className="admin-dashboard-sort-by-name">Sort by:</span>
            <select
              name="sort-by"
              id="admin-dashboard-sort-by-id"
              value={sortCriteria}
              onChange={(e) => setSortCriteria(e.target.value)}
            >
              <option value="">None</option>
              <option value="last_login">Last Login</option>
              <option value="cart_count">Cart Count</option>
              <option value="state_name">State</option>
            </select>
          </div>
        </div>
      </div>
      {filteredUsers && Array.isArray(filteredUsers) ? (
        <div className="mobile-admin-dashboard-main-user-preorder-container">
          <div className="mobile-admin-dashboard-main-user-preorder-header">
            <span className="mobile-admin-dashboard-main-user-preorder-heading">
              Recent Carts
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-item-head">
          <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "10%", textAlign: "center" }}
            >
              S.No
            </span>
            <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "12%", textAlign: "center" }}
            >
              Login Date
            </span>
            <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "22%", textAlign: "center" }}
            >
              User Name
            </span>
            <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "24%", textAlign: "center" }}
            >
              Phone Number
            </span>
            <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "16%", textAlign: "center" }}
            >
              State Name
            </span>
            <span
              className="mobile-admin-dashboard-item-each-head"
              style={{ fontSize: "12px", width: "16%", textAlign: "center" }}
            >
              Order Cnt.
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-prebook-container">
            {filteredUsers &&
              Array.isArray(filteredUsers) &&
              filteredUsers?.map((user, index) => (
                <div
                  className="mobile-admin-dashboard-main-each-user"
                  key={index}
                >
                  <div
                    className="mobile-admin-dashboard-main-each-user-top"
                    style={{
                      boxShadow:
                        user?.UserID === openOrder &&
                        "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                    }}
                    key={index}
                  >
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </span>
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "12%",
                        textAlign: "center",
                      }}
                    >
                      {user?.LastLogin !== null ? (
                        new Date(user?.LastLogin).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        })
                      ) : (
                        <b
                          style={{
                            color: "var(--color-peach)",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Not Login
                        </b>
                      )}
                    </span>
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "22%",
                        textAlign: "center",
                      }}
                    >
                      {user?.FirstName}
                    </span>
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "24%",
                        textAlign: "center",
                      }}
                    >
                      {user?.PhoneNumber}
                    </span>
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "16%",
                        textAlign: "center",
                      }}
                    >
                      {user?.stateName}
                    </span>
                    
                    <span
                      className="mobile-admin-user-each-attr"
                      style={{
                        fontSize: "12px",
                        width: "16%",
                        textAlign: "center",
                      }}
                    >
                      {user?.OrderCount}
                      {openOrder === user?.UserID ? (
                        <KeyboardArrowDownOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(null)}
                        />
                      ) : (
                        <ChevronRightOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenOrder(user?.UserID);
                            findAllOrderUsingUserID(user?.UserID);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  {openOrder === user?.UserID && (
                     <div className="mobile-admin-dashboard-main-user-item-head">
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "20%", textAlign: "center" }}
                     >
                       Order Id
                     </span>
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "13%", textAlign: "center" }}
                     >
                       Order Date
                     </span>
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "15%", textAlign: "center" }}
                     >
                       Discount
                     </span>
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "20%", textAlign: "center" }}
                     >
                       Amount
                     </span>
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "22%", textAlign: "center" }}
                     >
                       Total Item Count.
                     </span>
                     <span
                       className="mobile-admin-dashboard-item-each-head"
                       style={{ fontSize: "12px", width: "10%", textAlign: "center" }}
                     >
                       Invoice
                     </span>
                   </div>
                    )}
                  {openOrder === user?.UserID &&
                      userOrders?.length > 0 &&
                      userOrders
                        ?.slice()
                        .sort(
                          (a, b) =>
                            new Date(b?.OrderDate) - new Date(a?.OrderDate)
                        )
                        .map((orders, index) => (
                          <div
                            className="admin-dashboard-main-each-user-top"
                            style={{
                              boxShadow:
                                user?.UserID === openOrder &&
                                "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                            }}
                            key={index}
                          >
                            <span
                              className="admin-dashboard-user-sno increase-so"
                              style={{fontSize:"12px",width:"20%",textAlign:'center'}}
                            >
                              {orders?.OrderNumber?.slice(0,6) + "..."}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{fontSize:"12px",width:"13%",textAlign:'center'}}
                            >
                              {orders?.OrderDate &&
                                new Date(orders?.OrderDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{fontSize:"12px",width:"15%",textAlign:'center'}}
                            >
                              ₹
                              {orders?.DiscountAmount === null
                                ? formatWithCommasWithoutDecimal(0)
                                : formatWithCommasWithoutDecimal(
                                    orders?.DiscountAmount
                                  )}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{fontSize:"12px",width:"20%",textAlign:'center'}}
                            >
                              ₹
                              {orders?.Amount === null
                                ? formatWithCommasWithoutDecimal(0)
                                : formatWithCommasWithoutDecimal(orders?.Amount)}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{fontSize:"12px",width:"22%",textAlign:'center'}}
                            >
                              <span
                                style={{ width: "14px", textAlign: "center" }}
                              >
                                {orders?.TotalItemCount}
                              </span>
                              <span
                                className="admin-order-view-1"
                                onClick={() =>
                                  window.open(
                                    `/user/orders/view_order/${orders?.OrderNumber}`,
                                    "_blank"
                                  )
                                }
                              >
                                View
                              </span>
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{fontSize:"12px",width:"10%",textAlign:'center'}}
                            >
                              <CloudDownloadIcon
                                style={{
                                  fontSize: "25px",
                                  color: "var(--color-peach)",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownloadInvoice(orders?.OrderNumber)
                                }
                              />
                            </span>
                          </div>
                        ))}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Pre-Book"} />
      )}
    </div>
  );
}

export default MobileAdminOrders;
