import React, { useEffect } from "react";
import "./MobileHeroBanner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchAllBannersMobileAsync,
  selectAllMobileBanners,
} from "../../features/HomePage/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MobileHeroBanner() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const mobileBanners = useSelector(selectAllMobileBanners);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(fetchAllBannersMobileAsync()).then(() => {
        handleClose();
      });
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [dispatch]);

  return (
    <div className="mobile-hero-banners-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Slider {...settings}>
        {mobileBanners &&
          mobileBanners?.map((banner, index) => (
            <div className="mobile-main-banner-div">
              <LazyLoadImage
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/${banner}`}
                alt=""
                className="mobile-main-banner-img"
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default MobileHeroBanner;
