import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import {
  fetchAllAdminUsersCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  fetchAllUserCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";

function AdminCarts() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUserCart);
  const userCarts = useSelector(selectAllAdminUserCarts);
  const [totals, setTotals] = useState(null);

  // console.log(totals);
  // console.log(users, userCarts);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // console.log(filteredUsers);

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (users) {
      setFilteredUsers(
        users?.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            user?.LastName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "cart_count") {
        sortedUsers.sort((a, b) => b?.CartCount - a?.CartCount);
      }
      else if(sortCriteria === "state_name"){
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllUserCartsAsync(UserID)).then(() => {
      handleClose();
    });
  };
  useEffect(() => {
    const getTotals = () => {
      let total = 0;
      let id = 0;

      for (let data of userCarts) {
        id = data?.UserID;
        const price = data?.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.Offer_price),
              data?.skuDetails?.GST
            )
          : calculateDealerPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.NDP_Price),
              data?.skuDetails?.GST
            );

        total += price * data?.buy_quantity;
      }

      setTotals((prevTotals) => ({
        ...prevTotals,
        [id]: formatWithCommasWithDecimal(total),
      }));
    };

    getTotals();
  }, [userCarts]);

  const calculateTotalCarts = () => {
    let totalCarts = 0;
    for (const cart of users) {
      totalCarts += cart?.CartCount;
    }
    return totalCarts;
  };
  return (
    <div className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="admin-dashboard-right-top-heading">
        <span className="admin-dashboard-heading-name">Carts</span>
        <img
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="admin-logo"
        />
      </div>
      <div className="admin-dashboard-main-container">
        <div className="admin-dashboard-middle-container">
          <div className="admin-dashboard-total-preorder-container">
            <span className="admin-dashboard-heading-total">Total Carts</span>
            <span className="admin-dashboard-total-count">
              {users && Array.isArray(users) ? calculateTotalCarts() : 0}
            </span>
            <ShoppingCartOutlinedIcon />
          </div>
          <div className="admin-dashboard-sort-by-search-container">
            <div className="admin-dashboard-sort-by">
              <span className="admin-dashboard-sort-by-name">Sort by:</span>
              <select
                name="sort-by"
                id="admin-dashboard-sort-by-id"
                value={sortCriteria}
                onChange={(e) => setSortCriteria(e.target.value)}
              >
                <option value="">None</option>
                <option value="last_login">Last Login</option>
                <option value="cart_count">Cart Count</option>
                <option value="state_name">State</option>
              </select>
            </div>
            <div className="admin-dashboard-middle-search-container">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search by name, phone or state"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="admin-dashboard-search-input"
              />
            </div>
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="admin-dashboard-main-user-preorder-container">
            <span className="admin-dashboard-main-user-preorder-heading">
              Recent Carts
            </span>
            <div className="admin-dashboard-main-user-item-head">
              <span className="admin-dashboard-serial-no increase-soon">
                S.No
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Last Login
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                User Name
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Phone Number
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Business Name
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                State
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Cart Count
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Total Amount
              </span>
            </div>
            <div className="admin-dashboard-main-user-prebook-container">
              {filteredUsers &&
                Array.isArray(filteredUsers) &&
                filteredUsers?.map((user, index) => (
                  <div className="admin-dashboard-main-each-user" key={index}>
                    <div
                      className="admin-dashboard-main-each-user-top"
                      style={{
                        boxShadow:
                          user?.UserID === openOrder &&
                          "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <span className="admin-dashboard-user-sno increase-soon">
                        {index + 1}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.LastLogin !== null ? (
                          new Date(user?.LastLogin).toLocaleDateString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            }
                          )
                        ) : (
                          <b
                            style={{
                              color: "var(--color-peach)",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Not Login Yet
                          </b>
                        )}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.FirstName}&nbsp;{user?.LastName}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.PhoneNumber}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.business_name}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.stateName}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.CartCount}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        <span style={{ textAlign: "center" }}>
                          {totals && totals[user?.UserID]
                            ? totals[user?.UserID]
                            : "---"}
                        </span>
                        {openOrder === user?.UserID ? (
                          <span
                            className="open-btn-admin"
                            style={{
                              background: "var(--color-peach)",
                            }}
                            onClick={() => setOpenOrder(null)}
                          >
                            Close
                          </span>
                        ) : (
                          <span
                            className="open-btn-admin"
                            style={{
                              background: "var(--color-green)",
                            }}
                            onClick={() => {
                              setOpenOrder(user?.UserID);
                              findAllOrderUsingUserID(user?.UserID);
                            }}
                          >
                            Open
                          </span>
                        )}
                      </span>
                    </div>

                    {openOrder === user?.UserID && (
                      <div className="admin-user-bottom-products">
                        <div className="main-carts-products-div-container">
                          {userCarts && userCarts?.length > 0 && (
                            <>
                              <div className="main-carts-products-details-align-key">
                                <span className="product-name-img">
                                  Product
                                </span>
                                <span className="product-carts-quantity">
                                  Qty
                                </span>
                                <span className="product-carts-price-per-unit">
                                  Price per unit
                                </span>
                                <span className="product-carts-price">
                                  {" "}
                                  Total Price <b>(Incl. GST)</b>
                                </span>
                              </div>
                              <div className="main-carts-products-contain-div">
                                {userCarts?.map((data, index) => (
                                  <div
                                    className="main-carts-product-div-each-container"
                                    key={index}
                                  >
                                    <div className="main-carts-product-div-name-img">
                                      <div className="main-carts-product-img">
                                        <LazyLoadImage
                                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                            data?.images && data?.images
                                          }`}
                                          alt=""
                                          effect="blur"
                                          wrapperProps={{
                                            // If you need to, you can tweak the effect transition using the wrapper style.
                                            style: {
                                              transitionDelay: "1s",
                                            },
                                          }}
                                        />
                                      </div>
                                      <div className="main-carts-product-name-desc">
                                        <div className="main-carts-product-name-desc-only">
                                          {data &&
                                          data?.skuDetails
                                            ?.manufacture_images ? (
                                            <LazyLoadImage
                                              className="main-carts-product-brand-img"
                                              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                                data &&
                                                data?.skuDetails
                                                  ?.manufacture_images
                                              }`}
                                              alt=""
                                              effect="blur"
                                              wrapperProps={{
                                                // If you need to, you can tweak the effect transition using the wrapper style.
                                                style: {
                                                  transitionDelay: "1s",
                                                },
                                              }}
                                            />
                                          ) : (
                                            <h3
                                              className="main-parts-product-name"
                                              style={{
                                                color: "var(--color-peach)",
                                                fontStyle: "italic",
                                              }}
                                            >
                                              {data?.skuDetails
                                                ?.manufacture_name &&
                                                data?.skuDetails
                                                  ?.manufacture_name}
                                            </h3>
                                          )}
                                          <span className="main-parts-product-desc">
                                            {(data?.skuDetails?.prod_name).slice(
                                              0,
                                              65
                                            )}
                                          </span>
                                          <div className="main-cart-product-properties-main-container">
                                            {data?.skuDetails?.size_name && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.skuDetails
                                                    ?.size_name &&
                                                    (data?.skuDetails?.size_name).slice(
                                                      0,
                                                      10
                                                    )}
                                                </span>
                                              </div>
                                            )}
                                            {data?.skuDetails?.colour_name && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.skuDetails
                                                    ?.colour_name &&
                                                    (data?.skuDetails?.colour_name).slice(
                                                      0,
                                                      15
                                                    )}
                                                </span>
                                              </div>
                                            )}
                                            {data?.skuDetails?.variant_name && (
                                              <div className="main-cart-product-size-container">
                                                <span className="main-cart-product-size">
                                                  {data?.skuDetails
                                                    ?.variant_name &&
                                                    (data?.skuDetails?.variant_name).slice(
                                                      0,
                                                      15
                                                    )}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="main-carts-product-quantity-div">
                                      <span>{data?.buy_quantity}</span>
                                    </div>
                                    <div className="main-carts-product-pricing-container">
                                      <div className="main-carts-product-ndp-offer-price">
                                        {data?.skuDetails?.NDP_Price && (
                                          <span
                                            className="main-carts-product-npd-price-container"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Dealer Price -{" "}
                                            <b
                                              style={{
                                                textDecoration:
                                                  data?.skuDetails
                                                    ?.Offer_price !== null &&
                                                  "line-through",
                                                fontSize:
                                                  data?.skuDetails
                                                    ?.Offer_price !== null &&
                                                  "10px",
                                              }}
                                            >
                                              {" "}
                                              ₹
                                              {formatWithCommasWithDecimal(
                                                calculateDealerPrice(
                                                  parseInt(
                                                    data?.skuDetails?.MRP_price
                                                  ),
                                                  parseInt(
                                                    data?.skuDetails?.NDP_Price
                                                  ),
                                                  data?.skuDetails?.GST
                                                )
                                              )}
                                            </b>
                                          </span>
                                        )}
                                        {data?.skuDetails?.Offer_price !==
                                          null && (
                                          <span className="main-carts-product-offer-price-span">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateOfferPrice(
                                                parseInt(
                                                  data?.skuDetails?.MRP_price
                                                ),
                                                parseInt(
                                                  data?.skuDetails?.Offer_price
                                                ),
                                                data?.skuDetails?.GST
                                              )
                                            )}
                                          </span>
                                        )}{" "}
                                      </div>
                                      {data?.skuDetails?.MRP_price && (
                                        <span className="main-carts-product-mrp-price-span">
                                          Product MRP - ₹
                                          {formatWithCommasWithDecimal(
                                            parseInt(
                                              data?.skuDetails?.MRP_price
                                            )
                                          )}
                                        </span>
                                      )}
                                    </div>
                                    <div className="main-carts-product-price-div">
                                      <div className="main-carts-product-price-main-container">
                                        ₹
                                        {formatWithCommasWithDecimal(
                                          (data?.skuDetails?.Offer_price
                                            ? calculateOfferPrice(
                                                parseInt(
                                                  data?.skuDetails?.MRP_price
                                                ),
                                                parseInt(
                                                  data?.skuDetails?.Offer_price
                                                ),
                                                data?.skuDetails?.GST
                                              )
                                            : calculateDealerPrice(
                                                parseInt(
                                                  data?.skuDetails?.MRP_price
                                                ),
                                                parseInt(
                                                  data?.skuDetails?.NDP_Price
                                                ),
                                                data?.skuDetails?.GST
                                              )) * data?.buy_quantity
                                        )}
                                      </div>
                                      {calculateDiscountPercentage(
                                        data?.skuDetails?.MRP_price,
                                        data?.skuDetails?.NDP_Price
                                      ) > 0 && (
                                        <div className="main-carts-product-dealer-margin-span">
                                          <LocalOfferOutlinedIcon />{" "}
                                          <span
                                            style={{
                                              textDecoration:
                                                data?.skuDetails
                                                  ?.Offer_price !== null &&
                                                "line-through",
                                              fontSize:
                                                data?.skuDetails
                                                  ?.Offer_price !== null &&
                                                "10px",
                                              margin: "0 0.2vw",
                                            }}
                                          >
                                            {" "}
                                            {calculateDiscountPercentage(
                                              data?.skuDetails?.MRP_price,
                                              calculateDealerPrice(
                                                parseInt(
                                                  data?.skuDetails?.MRP_price
                                                ),
                                                parseInt(
                                                  data?.skuDetails?.NDP_Price
                                                ),
                                                data?.skuDetails?.GST
                                              )
                                            )}
                                            %
                                          </span>
                                          {data?.skuDetails?.Offer_price !==
                                            null && (
                                            <span className="main-carts-product-dealer-margin-offer-ribbon">
                                              {calculateDiscountPercentage(
                                                data?.skuDetails?.MRP_price,
                                                calculateOfferPrice(
                                                  parseInt(
                                                    data?.skuDetails?.MRP_price
                                                  ),
                                                  parseInt(
                                                    data?.skuDetails
                                                      ?.Offer_price
                                                  ),
                                                  data?.skuDetails?.GST
                                                )
                                              )}
                                              %
                                            </span>
                                          )}{" "}
                                          Dealer Margin
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminCarts;
