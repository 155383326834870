import React, { useEffect, useRef, useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import MobileOTPVerification from "../MobileOTPVerification";
import { connect } from "react-redux";
import "./AutoLoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../config";
import {
  setCodeEntered,
  setLoginMode,
  setOTPVerified,
  setPhoneNumber,
} from "../../../store/slices/userSlice";
import { toggleOverlay } from "../../../store/slices/headerSlice";
import { setToken } from "../../../features/auth/authSlice";
import AutoOTPVerification from "./AutoOTPVerification";

function AutoLoginPage({
  user,
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setShowAutoLogin,
}) {
  // GSAP CODE
  const containerRef = useRef(null);
  const phoneInputRef = useRef(null);
  useEffect(() => {
    // GSAP animation for loading the component
    gsap.fromTo(
      containerRef.current,
      { y: "100%", opacity: 0 },
      { y: "0%", opacity: 1, duration: 0.5, ease: "power3.out" }
    );

    // Focus on the phone number input when the component is opened
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);
  const handleCloseTab = () => {
    // GSAP animation for closing the component
    gsap.to(containerRef.current, {
      y: "100%",
      opacity: 0,
      duration: 0.5,
      ease: "power3.in",
      onComplete: () => setShowAutoLogin(false),
    });
  };

  // MAIN CODE
  const { loginMode, phoneNumber } = user;
  const [phoneExists, setPhoneExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const handletoggle = () => {
    if (loginMode === "login") {
      setLoginMode("signup");
      setPhoneNumber(null);
    } else {
      setLoginMode("login");
      setPhoneNumber(null);
    }
  };
  const handleSendOTP = async (event) => {
    handleOpen();
    event.preventDefault();
    const formData = new FormData(event.target);
    const phoneNumber = formData.get("phoneNumber");
    if (loginMode === "login") {
      try {
        const response = await fetch(
          `${API_BASE_URL}login_number/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log(data);
          // Swal.fire({
          //   title: data.success,
          //   text: data.success,
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
          handleClose();
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          handleClose();
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
            handleClose();
          // console.log(data);
          // Swal.fire({
          //   title: data.success,
          //   text: data.success,
          //   icon: "success",
          //   confirmButtonText: "OK",
          // });
          setPhoneNumber(phoneNumber);
        } else {
            handleClose();
          console.error("API request failed:", response.statusText);
          Swal.fire({
            title: "OTP not Sent",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        handleClose();
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handlePhoneNumberExist = async (phone) => {
    // console.log("enter exist func");
    try {
      const response = await fetch(
        `${API_BASE_URL}singup_phone_verify/${phone}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPhoneExists(true);
      } else {
        setPhoneExists(false);
      }
    } catch (error) {
      console.error("Error checking phone number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    // console.log("enter input change");
    const { name, value } = e.target;
    // console.log(name, value);
    if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length === 10
    ) {
      // console.log("enter condintion");
      handlePhoneNumberExist(value);
    } else if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length < 10
    ) {
      setPhoneExists(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={handleCloseTab}></div>
      <div className="auto-login-page-container" ref={containerRef}>
        <div className="auto-login-page-close" onClick={handleCloseTab}>
          <CloseSharpIcon />
        </div>
        <div className="auto-login-page-heading">
          <span className="auto-login-page-text">
            {loginMode === "login" ? "Login" : "Sign Up"} to continue
          </span>
        </div>
        <div className="auto-login-page-login-part">
          {phoneNumber ? (
            <AutoOTPVerification
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleCloseTab={handleCloseTab}
            />
          ) : (
            <form onSubmit={handleSendOTP} className="auto-login-page-form">
              <label htmlFor="phoneNumber" className="auto-login-form-label">
                Phone Number
              </label>
              <input
                type="number"
                id="auto-phoneNumber"
                maxLength="10"
                max="9999999999"
                name="phoneNumber"
                onChange={handleInputChange}
                pattern="\d{10}"
                ref={phoneInputRef}
                onKeyPress={(e) => {
                  // Allow only numbers (0-9) and backspace (8)
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  // Limit input to 10 digits
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
                required
              />
              {phoneExists && (
                <span
                  className="mobile-auth-phone-exist-or-not"
                  style={{
                    color: "var(--color-peach)",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Ph. Number already registered with Cyclecircle
                </span>
              )}
              <button id="auto-login-btn" className={`${loading ? "auto-btn-active":""}`} type="submit" disabled={loading}>
                {loading ? (
                    <>
                    <div className="auto-spinner"></div>
                    </>
                ):(
                "Send OTP"
            )}
              </button>
            </form>
          )}
        </div>
        <div
          className="mobile-auth-dont-have-an-account"
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "700",
            fontSize: "15px",
          }}
        >
          <p>
            {loginMode === "login"
              ? "Don't have an account"
              : "Already have an account"}
            ?
          </p>
          <span
            className="mobile-auth-dont-have-an-account-sign-up"
            onClick={handletoggle}
            style={{ color: "var(--color-purple)", fontSize: "16px" }}
          >
            {loginMode === "login" ? "Sign up" : "Login"}
          </span>
        </div>
        <div className="auto-login-terms-container">
          <span className="auto-login-terms-text">
            By continuing, you agree to Cyclecircle's{" "}
            <Link to="/privacypolicy">Privacy Policy</Link> and{" "}
            <Link to="/support">User Agreement</Link>{" "}
          </span>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLoginPage);
