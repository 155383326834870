import React, { useEffect, useState } from "react";
import "./MobileAdminPreOrder.css";
import { IMAGE_BASE } from "../../config";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import {
  fetchAllAdminUsersCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  fetchAllUserCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../../components/Carts/NoCartItem";

function MobileAdminCarts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUserCart);
  const userCarts = useSelector(selectAllAdminUserCarts);
  const [totals, setTotals] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // console.log(filteredUsers);

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (users) {
      setFilteredUsers(
        users?.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "cart_count") {
        sortedUsers.sort((a, b) => b?.CartCount - a?.CartCount);
      } else if (sortCriteria === "state_name") {
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllUserCartsAsync(UserID)).then(() => {
      handleClose();
    });
  };
  useEffect(() => {
    const getTotals = () => {
      let total = 0;
      let id = 0;

      for (let data of userCarts) {
        id = data?.UserID;
        const price = data?.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.Offer_price),
              data?.skuDetails?.GST
            )
          : calculateDealerPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.NDP_Price),
              data?.skuDetails?.GST
            );

        total += price * data?.buy_quantity;
      }

      setTotals((prevTotals) => ({
        ...prevTotals,
        [id]: formatWithCommasWithDecimal(total),
      }));
    };

    getTotals();
  }, [userCarts]);

  const calculateTotalCarts = () => {
    let totalCarts = 0;
    for (const cart of users) {
      totalCarts += cart?.CartCount;
    }
    return totalCarts;
  };
  return (
    <div className="mobile-admin-preorder-v2-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-admin-dashboard-middle-container">
        <div className="mobile-admin-dashboard-middle-search-container">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by name, phone or state"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mobile-admin-dashboard-search-input"
          />
        </div>
        <div className="mobile-admin-dashboard-sort-by-container">
          <div className="mobile-admin-dashboard-total-preorder-container">
            <span className="mobile-admin-dashboard-heading-total">
              Total Carts
            </span>
            <span className="mobile-admin-dashboard-total-count">
              {users && Array.isArray(users) ? calculateTotalCarts() : 0}
            </span>
            <ShoppingCartOutlinedIcon />
          </div>
          <div className="admin-dashboard-sort-by">
            <span className="admin-dashboard-sort-by-name">Sort by:</span>
            <select
              name="sort-by"
              id="admin-dashboard-sort-by-id"
              value={sortCriteria}
              onChange={(e) => setSortCriteria(e.target.value)}
            >
              <option value="">None</option>
              <option value="last_login">Last Login</option>
              <option value="cart_count">Cart Count</option>
              <option value="state_name">State</option>
            </select>
          </div>
        </div>
      </div>
      {filteredUsers && Array?.isArray(filteredUsers) ? (
        <div className="mobile-admin-dashboard-main-user-preorder-container">
          <div className="mobile-admin-dashboard-main-user-preorder-header">
            <span className="mobile-admin-dashboard-main-user-preorder-heading">
              Recent Carts
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-item-head">
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"8%",textAlign:'center'}}>
              Login Date
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"18%",textAlign:'center'}}>
              User Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"24%",textAlign:'center'}}>
              Phone Number
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"15%",textAlign:'center'}}>
              State Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"10%",textAlign:'center'}}>
              Cart Cnt.
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"25%",textAlign:'center'}}>Total Order Amount</span>
          </div>
          <div className="mobile-admin-dashboard-main-user-prebook-container">
            {filteredUsers &&
              Array?.isArray(filteredUsers) &&
              filteredUsers?.map((user, index) => (
                <div
                  className="mobile-admin-dashboard-main-each-user"
                  key={index}
                >
                  <div
                    className="mobile-admin-dashboard-main-each-user-top"
                    style={{
                      boxShadow:
                        user?.UserID === openOrder &&
                        "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                    }}
                    key={index}
                  >
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"8%",textAlign:'center'}}>
                    {user?.LastLogin !== null ? (
                          new Date(user?.LastLogin).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                            }
                          )
                        ) : (
                          <b
                            style={{
                              color: "var(--color-peach)",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Not Login
                          </b>
                        )}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"18%",textAlign:'center'}}>
                      {user?.FirstName}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"24%",textAlign:'center'}}>
                      {user?.PhoneNumber}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"15%",textAlign:'center'}}>
                      {user?.stateName}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"10%",textAlign:'center'}}>
                      {user?.CartCount}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"25%",textAlign:'center'}}>
                      {totals && totals[user?.UserID]
                        ? totals[user?.UserID]
                        : "--------"}
                     {openOrder === user?.UserID ? (
                        <KeyboardArrowDownOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(null)}
                        />
                      ) : (
                        <ChevronRightOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenOrder(user?.UserID);
                            findAllOrderUsingUserID(user?.UserID);
                          }}
                        />
                        
                      )}
                    </span>
                  </div>
                  {openOrder === user?.UserID && (
                    <div className="mobile-admin-user-bottom-products">
                      <div className="main-carts-products-div-container">
                        {userCarts && userCarts?.length > 0 && (
                          <div className="mobile-main-carts-products-contain-div">
                            {userCarts?.map((data, index) => (
                              <div
                                style={{
                                  width: "100%",
                                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                                  padding: "10px 0",
                                  background: "white",
                                }}
                              >
                                <div
                                  className="mobile-main-carts-product-div-each-main-container"
                                  key={index}
                                >
                                  <div
                                    className="mobile-main-carts-product-img-div"
                                    onClick={() =>
                                      navigate(
                                        `/product/${
                                          data?.prod_ID && data?.prod_ID
                                        }`
                                      )
                                    }
                                  >
                                    <img
                                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                        data?.images && data?.images
                                      }`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="mobile-main-carts-product-details-div">
                                    <div className="mobile-main-carts-products-brands-logo">
                                      {data &&
                                      data?.skuDetails?.manufacture_images ? (
                                        <img
                                          className="mobile-main-carts-product-brand-img"
                                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                            data &&
                                            data?.skuDetails?.manufacture_images
                                          }`}
                                          style={{ height: "18px" }}
                                          alt=""
                                        />
                                      ) : (
                                        <h3 className="mobile-main-parts-product-name">
                                          {data?.skuDetails?.manufacture_name &&
                                            data?.skuDetails?.manufacture_name}
                                        </h3>
                                      )}
                                    </div>
                                    <span className="mobile-main-carts-product-name">
                                      {data?.skuDetails?.prod_name &&
                                        data?.skuDetails?.prod_name
                                          ?.slice(0, 45)
                                          ?.toLowerCase()
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          data?.skuDetails?.prod_name
                                            ?.slice(0, 45)
                                            ?.toLowerCase()
                                            ?.slice(1)}
                                      {data?.skuDetails?.prod_name?.length >
                                        45 && "..."}
                                    </span>
                                    <div className="mobile-main-carts-product-ratings-div">
                                      <div className="mobile-main-carts-products-avg-rating-div">
                                        <span>4.7</span>
                                        <StarRoundedIcon />
                                      </div>
                                      <span className="rating-divider">|</span>
                                      <span className="mobile-main-carts-products-ratings-total">
                                        4K
                                      </span>
                                    </div>
                                    <div className="mobile-main-carts-products-price-div">
                                      <span className="mobile-main-carts-products-sale-price">
                                        Dealer Price- ₹
                                        <b
                                          style={{
                                            textDecoration:
                                              data?.skuDetails?.Offer_price !==
                                                null && "line-through",
                                            fontSize:
                                              data?.skuDetails?.Offer_price !==
                                                null && "3vw",
                                          }}
                                        >
                                          {formatWithCommasWithDecimal(
                                            calculateDealerPrice(
                                              parseInt(
                                                data?.skuDetails?.MRP_price
                                              ),
                                              parseInt(
                                                data?.skuDetails?.NDP_Price
                                              ),
                                              data?.skuDetails?.GST
                                            )
                                          )}
                                        </b>
                                        {Object.keys(userDetail)?.length > 0 &&
                                          data?.skuDetails?.Offer_price !==
                                            null && (
                                            <span className="mobile-promotion-product-offer-price">
                                              ₹
                                              {formatWithCommasWithDecimal(
                                                calculateOfferPrice(
                                                  parseInt(
                                                    data?.skuDetails?.MRP_price
                                                  ),
                                                  parseInt(
                                                    data?.skuDetails
                                                      ?.Offer_price
                                                  ),
                                                  data?.skuDetails?.GST
                                                )
                                              )}
                                            </span>
                                          )}
                                      </span>
                                      <span className="mobile-main-carts-products-mrp-price">
                                        Product MRP- ₹
                                        {formatWithCommasWithDecimal(
                                          parseInt(data?.skuDetails?.MRP_price)
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mobile-main-carts-products-qty-price-details"
                                  style={{ padding: "0 10px" }}
                                >
                                  <div className="mobile-main-carts-products-qty">
                                    <span>Qty</span>
                                    <div className="mobile-main-carts-products-inc-desc-qty">
                                      <span className="mobile-main-carts-qty-added">
                                        {data?.buy_quantity}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mobile-main-carts-products-price-accordian-open">
                                    <div className="mobile-main-carts-products-show-price">
                                      <span className="mobile-main-carts-products-pricing">
                                        ₹{" "}
                                        {formatWithCommasWithDecimal(
                                          parseInt(
                                            data?.skuDetails?.Offer_price
                                              ? calculateOfferPrice(
                                                  parseInt(
                                                    data?.skuDetails?.MRP_price
                                                  ),
                                                  parseInt(
                                                    data?.skuDetails
                                                      ?.Offer_price
                                                  ),
                                                  data?.skuDetails?.GST
                                                )
                                              : calculateDealerPrice(
                                                  parseInt(
                                                    data?.skuDetails?.MRP_price
                                                  ),
                                                  parseInt(
                                                    data?.skuDetails?.NDP_Price
                                                  ),
                                                  data?.skuDetails?.GST
                                                )
                                          ) * data?.buy_quantity
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Pre-Book"} />
      )}
    </div>
  );
}

export default MobileAdminCarts;
