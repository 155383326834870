import React, { useEffect, useState } from "react";
import "./MobileAdminPreOrder.css";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../../components/Carts/NoCartItem";

function MobileAdminPreOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const userPreBook = useSelector(selectAllPreordersUser);
  // console.log(userPreBook);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userPreBook);

  const [openOrder, setOpenOrder] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID)).then(
        () => {
          handleClose();
        }
      );
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (userPreBook) {
      setFilteredUsers(
        userPreBook.filter(
          (user) =>
            user?.FirstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.LastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.PhoneNumber.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, userPreBook]);

  const wantToFulfilled = (id) => {
    // console.log(fFilled);
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(fFilled)).then(() => {
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID));
      setFFilled(null);
      handleClose();
    });
  };
  const handleFulfilled = (id) => {
    setFFilled(id);
  };

  const calculateTotalAmount = (user) => {
    let totalAmount = 0;

    if (user && user?.Preorders) {
      for (const preorder of user?.Preorders) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  const calculateOverallAmount = () => {
    let overallAmount = 0;

    if (userPreBook && userPreBook?.length > 0) {
      for (const user of userPreBook) {
        if (user && user?.Preorders) {
          for (const preorder of user?.Preorders) {
            // Choose the price: offer price if available, otherwise NDP price
            const price = preorder?.Offer_price
              ? calculateOfferPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.Offer_price),
                  preorder?.GST
                )
              : calculateDealerPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.NDP_price),
                  preorder?.GST
                );

            // Calculate the amount for this preorder item
            const amount = price * preorder?.quantity;

            // Add to the total amount
            overallAmount += amount;
          }
        }
      }
    }
    return overallAmount;
  };
  return (
    <div className="mobile-admin-preorder-v2-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-admin-dashboard-middle-container">
        <div className="mobile-admin-dashboard-middle-search-container">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by name or phone"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mobile-admin-dashboard-search-input"
          />
        </div>
        <div className="mobile-admin-dashboard-total-preorder-container">
          <span className="mobile-admin-dashboard-heading-total">
            Total Pre-Orders
          </span>
          <span className="mobile-admin-dashboard-total-count">
            {userPreBook && Array.isArray(userPreBook)
              ? userPreBook?.length
              : 0}
          </span>
          <WorkHistoryOutlinedIcon />
        </div>
      </div>
      {filteredUsers && Array.isArray(filteredUsers) ? (
        <div className="mobile-admin-dashboard-main-user-preorder-container">
          <div className="mobile-admin-dashboard-main-user-preorder-header">
            <span className="mobile-admin-dashboard-main-user-preorder-heading">
              Pre-Orders
            </span>
            <span className="mobile-admin-dashboard-main-user-preorder-overall">
              Overall Amount:
              <b style={{ fontSize: "14px" }}>
                {" "}
                ₹{formatWithCommasWithDecimal(calculateOverallAmount())}
              </b>
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-item-head">
            <span className="mobile-admin-dashboard-serial-no">S.No</span>
            <span className="mobile-admin-dashboard-item-each-head">
              User Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head">
              Phone No
            </span>
            <span className="mobile-admin-dashboard-item-each-head">Order</span>
            <span className="mobile-admin-dashboard-item-dot">-</span>
          </div>
          <div className="mobile-admin-dashboard-main-user-prebook-container">
            {filteredUsers &&
              Array.isArray(filteredUsers) &&
              filteredUsers?.map((user, index) => (
                <div
                  className="mobile-admin-dashboard-main-each-user"
                  key={index}
                >
                  <div
                    className="mobile-admin-dashboard-main-each-user-top"
                    style={{
                      boxShadow:
                        user?.UserID === openOrder &&
                        "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                    }}
                    key={index}
                  >
                    <span className="mobile-admin-dashboard-user-sno">
                      {index + 1}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      {user?.FirstName}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      {user?.PhoneNumber}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      ₹{formatWithCommasWithDecimal(calculateTotalAmount(user))}
                      {openOrder === user?.UserID ? (
                        <KeyboardArrowDownOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(null)}
                        />
                      ) : (
                        <ChevronRightOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(user?.UserID)}
                        />
                      )}
                    </span>
                    <span
                      className="mobile-admin-user-req-fulfilled"
                      onClick={() => handleFulfilled(user?.UserID)}
                    >
                      ?
                    </span>
                    {fFilled !== null && (
                      <div className="admin-req-fulfilled-overlay">
                        <div className="mobile-logout-overlay"></div>
                        <div className="logout-overlay-container">
                          <h3 style={{ margin: "0" }}>Fulfilled?</h3>
                          <span
                            className="want-logout"
                            style={{ textAlign: "center" }}
                          >
                            Are you sure,This package is fulfilled?
                          </span>
                          <div className="logout-btn-div">
                            <span
                              className="no-btn"
                              onClick={() => handleFulfilled(null)}
                            >
                              No
                            </span>
                            <span
                              className="yes-btn"
                              onClick={() => wantToFulfilled(user?.UserID)}
                            >
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {openOrder === user?.UserID && (
                    <div className="mobile-admin-user-bottom-products">
                      <div className="main-carts-products-div-container">
                        {user?.Preorders && user?.Preorders?.length > 0 && (
                          <div className="mobile-main-carts-products-contain-div">
                            {user?.Preorders?.map((data, index) => (
                              <div
                                style={{
                                  width: "100%",
                                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                                  padding: "10px 0",
                                  background: "white",
                                }}
                              >
                                <div
                                  className="mobile-main-carts-product-div-each-main-container"
                                  key={index}
                                >
                                  <div
                                    className="mobile-main-carts-product-img-div"
                                    onClick={() =>
                                      navigate(
                                        `/product/${
                                          data?.prod_ID && data?.prod_ID
                                        }`
                                      )
                                    }
                                  >
                                    <img
                                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                        data?.image && data?.image
                                      }`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="mobile-main-carts-product-details-div">
                                    <div className="mobile-main-carts-products-brands-logo">
                                      {data && data?.manufacture_images ? (
                                        <img
                                          className="mobile-main-carts-product-brand-img"
                                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                            data && data?.manufacture_images
                                          }`}
                                          style={{ height: "18px" }}
                                          alt=""
                                        />
                                      ) : (
                                        <h3 className="mobile-main-parts-product-name">
                                          {data?.manufacture_name &&
                                            data?.manufacture_name}
                                        </h3>
                                      )}
                                    </div>
                                    <span className="mobile-main-carts-product-name">
                                      {data?.prod_name &&
                                        data?.prod_name
                                          ?.slice(0, 45)
                                          ?.toLowerCase()
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          data?.prod_name
                                            ?.slice(0, 45)
                                            ?.toLowerCase()
                                            ?.slice(1)}
                                      {data?.prod_name?.length > 45 && "..."}
                                    </span>
                                    <div className="mobile-main-carts-product-ratings-div">
                                      <div className="mobile-main-carts-products-avg-rating-div">
                                        <span>4.7</span>
                                        <StarRoundedIcon />
                                      </div>
                                      <span className="rating-divider">|</span>
                                      <span className="mobile-main-carts-products-ratings-total">
                                        4K
                                      </span>
                                    </div>
                                    <div className="mobile-main-carts-products-price-div">
                                      <span className="mobile-main-carts-products-sale-price">
                                        Dealer Price- ₹
                                        <b
                                          style={{
                                            textDecoration:
                                              data?.Offer_price !== null &&
                                              "line-through",
                                            fontSize:
                                              data?.Offer_price !== null &&
                                              "3vw",
                                          }}
                                        >
                                          {formatWithCommasWithDecimal(
                                            calculateDealerPrice(
                                              parseInt(data?.MRP_price),
                                              parseInt(data?.NDP_price),
                                              data?.GST
                                            )
                                          )}
                                        </b>
                                        {Object.keys(userDetail)?.length > 0 &&
                                          data?.Offer_price !== null && (
                                            <span className="mobile-promotion-product-offer-price">
                                              ₹
                                              {formatWithCommasWithDecimal(
                                                calculateOfferPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.Offer_price),
                                                  data?.GST
                                                )
                                              )}
                                            </span>
                                          )}
                                      </span>
                                      <span className="mobile-main-carts-products-mrp-price">
                                        Product MRP- ₹
                                        {formatWithCommasWithDecimal(
                                          parseInt(data?.MRP_price)
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mobile-main-carts-products-qty-price-details"
                                  style={{ padding: "0 10px" }}
                                >
                                  <div className="mobile-main-carts-products-qty">
                                    <span>Qty</span>
                                    <div className="mobile-main-carts-products-inc-desc-qty">
                                      <span className="mobile-main-carts-qty-added">
                                        {data?.quantity}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mobile-main-carts-products-price-accordian-open">
                                    <div className="mobile-main-carts-products-show-price">
                                      <span className="mobile-main-carts-products-pricing">
                                        ₹{" "}
                                        {formatWithCommasWithDecimal(
                                          parseInt(
                                            data?.Offer_price
                                              ? calculateOfferPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.Offer_price),
                                                  data?.GST
                                                )
                                              : calculateDealerPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.NDP_price),
                                                  data?.GST
                                                )
                                          ) * data?.quantity
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Pre-Book"} />
      )}
    </div>
  );
}

export default MobileAdminPreOrder;
