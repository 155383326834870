import React, { useState, useEffect } from "react";
import "./Product.css";
import { connect } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { IMAGE_BASE } from "../../../config";
import ListingHeader from "../Filter/FilterHeader/ListingHeader";
import ListingPagination from "../Pagination/ListingPagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  fetchAllCategoryProductsAsync,
  selectAllCategoryProducts,
  setBrands,
  setMaterials,
  setSelectedCategoryID,
} from "../../../features/listingPage/listingPageSlice";
import {
  fetchAllWishlistsAsync,
  addToWishlistsAsync,
  removeFromWishlistAsync,
  selectAllWishlists,
} from "../../../features/wishlist/wishlistSlice";
import {
  fetchAllCartsAsync,
  removeFromCartsAsync,
  selectAllCarts,
} from "../../../features/cartPage/cartPageSlice";
import { selectUser } from "../../../features/auth/authSlice";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../../store/slices/headerSlice";
import HoverSlider from "../ListingProduct/HoverSlider";
import SelectDesktopVariant from "../../EndPages/SelectDesktopVariant";
import { selectAllFilter } from "../../../store/slices/allFilterSlice";
import { setBreadcrumb } from "../../../store/slices/breadCrumbsSlice";
import NoProductFound from "../ListingProduct/NoProductFound";
import AuthenticationPage from "../../Authentication/AuthenticationPage";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import cartOutlineLogo from "../../../img/navigation/cart-bookmark-outline.png";
import cartFilledLogo from "../../../img/navigation/cart-bookmark-filled.png";
import { findqty } from "../../../data";
import ndpHiddenLogo from "../../../icons/Price-Blur.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { calculateDealerPrice, calculateDiscountPercentage, calculateOfferPrice, formatWithCommasWithoutDecimal } from "../../../utils/utils";

function Product({ isOverlayVisible, toggleOverlay,currentPage,setCurrentPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pdts = useSelector(selectAllCategoryProducts);
  const products = pdts.Data;
  const qtys = pdts.quantity;

  const wishlist = useSelector(selectAllWishlists);
  const cart = useSelector(selectAllCarts);
  // console.log(cart);
  const userDetail = useSelector(selectUser);
  const [openDesktopVariant, setOpenDesktopVariant] = useState(null);
  const [items, setItems] = useState(products?.product || []);
  const [currentItems, setCurrentItems] = useState([]);
  
  const itemsPerPage = 15;
  const allfilter = useSelector(selectAllFilter);
  const { CategoryID, categoryName } = useParams();
  const [open, setOpen] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  // set selectedCategory id
  useEffect(() => {
    if (CategoryID) {
      dispatch(setSelectedCategoryID(CategoryID));
    }

    // Set all Brands of respective category,subCategory
    if (products?.manufacture_name && products?.manufacture_name) {
      dispatch(
        setBrands(products?.manufacture_name && products?.manufacture_name)
      );
    }
    if (products?.material && products?.material) {
      dispatch(setMaterials(products?.material && products?.material));
    }

    // Update breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        // subCategoryID: null,
        subCategoryName: null,
        ProductID: null,
        productName: "",
      })
    );
  }, [
    dispatch,
    CategoryID,
    categoryName,
    products?.manufacture_name,
    products?.material,
  ]);

  useEffect(() => {
    // console.log(allfilter);
    if (
      products &&
      products.product &&
      Array.isArray(allfilter.brands) &&
      Array.isArray(allfilter.materials)
    ) {
      const filteredItems = products.product.filter((product) => {
        const brandFilterPassed =
          allfilter.brands.length === 0 ||
          allfilter.brands.includes(product?.manufacture_name);
        const materialFilterPassed =
          allfilter.materials.length === 0 ||
          allfilter.materials.includes(product?.material_name);
        const shippingFilterPassed =
          !allfilter.shipping ||
          allfilter.shipping === "standard" ||
          allfilter.shipping.includes(product?.shipping);
        const ratingFilterPassed =
          allfilter.rating === 0 ||
          parseInt(product.net_rating) >= parseInt(allfilter.rating);
        const dealerMarginFilterPassed =
          allfilter.dealerMargin === 0 ||
          parseInt(
            calculateDiscountPercentage(
              product?.skuDetails[0]?.MRP_price,
              product?.skuDetails[0]?.Offer_price
                ? calculateOfferPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.Offer_price),
                    product?.GST
                  )
                : calculateDealerPrice(
                    parseInt(product?.skuDetails[0]?.MRP_price),
                    parseInt(product?.skuDetails[0]?.NDP_Price),
                    product?.GST
                  )
            )
          ) >= parseInt(allfilter.dealerMargin);
        const minPriceFilterPassed =
          allfilter.minPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            product?.GST
          ) >= parseInt(allfilter.minPrice);
        const maxPriceFilterPassed =
          allfilter.maxPrice === 0 ||
          calculateDealerPrice(
            parseInt(product?.skuDetails[0]?.MRP_price),
            parseInt(product?.skuDetails[0]?.NDP_Price),
            parseInt(product?.GST)
          ) <= parseInt(allfilter.maxPrice);

        // Check if the product has the specified badge (excluding ".png" extension)
        const badgeFilterPassed =
          allfilter.shopByOffer === "" ||
          (product?.Badges && product?.Badges.replace(".png", "")) ===
            allfilter.shopByOffer;

        return (
          brandFilterPassed &&
          materialFilterPassed &&
          shippingFilterPassed &&
          ratingFilterPassed &&
          dealerMarginFilterPassed &&
          minPriceFilterPassed &&
          maxPriceFilterPassed &&
          badgeFilterPassed
        );
      });

      // console.log(filteredItems);

      let sortedItems = [...filteredItems];

      if (allfilter.sortPrice === "desc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            ) -
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            )
        );
      } else if (allfilter.sortPrice === "asc") {
        sortedItems.sort(
          (a, b) =>
            calculateDealerPrice(
              parseInt(a?.skuDetails[0]?.MRP_price),
              parseInt(a?.skuDetails[0]?.NDP_Price),
              parseInt(a?.GST)
            ) -
            calculateDealerPrice(
              parseInt(b?.skuDetails[0]?.MRP_price),
              parseInt(b?.skuDetails[0]?.NDP_Price),
              parseInt(b?.GST)
            )
        );
      } else if (allfilter.sortRating === "desc") {
        sortedItems.sort((a, b) => b?.net_rating - a?.net_rating);
      } else if (allfilter.sortRating === "asc") {
        sortedItems.sort((a, b) => a?.net_rating - b?.net_rating);
      }

      setItems(sortedItems);
    } else {
      return;
    }
  }, [products, allfilter]);

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      })
    ); // Fetch updated wishlist
    e.preventDefault();
  };

  const handleRemoveFromWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
    e.preventDefault();
  };

  // PAGINATION
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //  setCurrentItems(Array.isArray(items)
  //     ? items.slice(indexOfFirstItem, indexOfLastItem)
  //     : []);
  //   console.log(currentItems);

  useEffect(() => {
    setCurrentItems(
      Array.isArray(items) ? items.slice(indexOfFirstItem, indexOfLastItem) : []
    );
  }, [items, indexOfFirstItem, indexOfLastItem]);

  // REMOVE FROM CART
  const removeFromCart = (data, e) => {
    // console.log(data?.skuDetails[0]?.SKU_ID);
    handleOpen();
    e.preventDefault();
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails[0]?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
    handleClose();
  };

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist && wishlist?.product) &&
      wishlist?.product?.some((item) => item.prod_ID === productId)
    );
  };
  // Function to check if a product is in the cart
  const isInCart = (productId) => {
    return;
    // return (
    //   Array.isArray(cart) &&
    //   cart.some((item) => parseInt(item?.skuDetails[0]?.prod_ID) === parseInt(productId))
    // );
  };

  const handleNavigate = (prod_id, event) => {
    event.preventDefault();
    navigate(`/product/${prod_id}`);
  };

  const handleOpenDesktopVariant = (id, e) => {
    setOpenDesktopVariant(id);
    e.preventDefault();
  };

  

 


  const handleMouseEnter = () => {
    return;
  };

  const handleMouseLeave = () => {
    return;
  };

  console.log(currentItems);
  return (
    <div className="products-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open || filtering} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="products-header-filter-container">
        <ListingHeader />
      </div> */}
      <div className="products-main-product-list-container">
        {currentItems && currentItems.length <= 0 && (
          <div className="products-main-product-no-product-container">
            <NoProductFound />
          </div>
        )}
        {currentItems && currentItems.length > 0 && (
          <div className="products-main-product-list-main-container">
            {currentItems.map((data) => (
              <div className="products-list-card-container" key={data?.prod_ID}>
                {data?.Badges && (
                  <div className="products-list-card-product-badges-container">
                    <img
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                        data?.Badges && data?.Badges
                      }`}
                      alt=""
                      className="product-list-card-product-badges"
                    />
                  </div>
                )}
                 {userDetail?.UserID === 1 && (
                  <span
                    className="products-list-card-product-badges-container"
                    style={{
                      padding: "3px",
                      fontSize: "12px",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {data?.prod_ID?.startsWith("9") ? (
                      <span
                        style={{
                          background: "var(--color-peach)",
                          color: "white",
                        }}
                      >
                        CC EXCLUSIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          background: "var(--color-green)",
                          color: "white",
                        }}
                      >
                        CC INCLUSIVE
                      </span>
                    )}
                  </span>
                )}
                {data?.manufacture_name !== "ESI" && (
                  <div className="products-list-card-favourite-container">
                    {isInWishlist(data.prod_ID) ? (
                      <img
                        src={cartFilledLogo}
                        alt=""
                        onClick={(e) => {
                          handleRemoveFromWishlist(data, e);
                        }}
                        id="product-list-card-favourite-svg"
                      />
                    ) : (
                      <img
                        src={cartOutlineLogo}
                        alt=""
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleAddToWishlist(data, e)
                            : handleLoginOverlay(e);
                        }}
                        id="product-list-card-favourite-svg"
                      />
                    )}
                  </div>
                )}
                <div
                  className="products-list-card-image-container"
                  onClick={(event) => handleNavigate(data.prod_ID, event)}
                >
                  <HoverSlider images={data?.images && data.images} />
                </div>
                <div className="products-list-card-product-details">
                  <div className="products-list-card-product-ratings-container">
                    <span className="products-list-card-product-rating">
                      {parseInt(data.net_rating).toFixed(1)}
                    </span>
                    <StarIcon id="product-list-card-star-svg" />
                    <span className="products-list-card-product-rating-divider">
                      |
                    </span>
                    <span className="products-list-card-product-no-of-rating">
                      313
                    </span>
                  </div>
                  <div className="products-list-card-product-cart-container">
                    {isInCart(data.prod_ID) ? (
                      <DoneOutlineOutlinedIcon
                        onClick={(e) => removeFromCart(data, e)}
                        id="product-list-card-cart-svg"
                      />
                    ) : (
                      <ShoppingCartOutlinedIcon
                        onClick={(e) => {
                          userDetail && userDetail?.UserID
                            ? handleOpenDesktopVariant(data.prod_ID, e)
                            : handleLoginOverlay(e);
                        }}
                        id="product-list-card-cart-svg"
                      />
                    )}
                  </div>
                  {data && data?.manufacture_images ? (
                    <img
                      className="products-list-card-product-brand-img"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <span className="products-list-card-product-brand-name">
                      {data.manufacture_name && data.manufacture_name}
                    </span>
                  )}
                  <div className="products-list-card-product-name">
                    <span className="products-list-card-product-name-span">
                      {data.prod_name && data.prod_name.slice(0, 45)}
                      {data.prod_name.length > 45 && "..."}
                    </span>
                  </div>
                  <div className="products-list-card-product-price-container">
                    <div className="products-list-card-product-current-price">
                      {data &&
                        data?.skuDetails &&
                        data?.skuDetails?.length > 0 &&
                        data?.skuDetails[0]?.NDP_Price && (
                          <>
                            <span className="products-list-card-product-current-price-span">
                              Dealer Price -{" "}
                              {Object.keys(userDetail).length > 0 ? (
                                <b
                                  className="current-price-b"
                                  style={{
                                    textDecoration:
                                      data?.skuDetails[0].Offer_price !==
                                        null && "line-through",
                                    fontSize:
                                      data?.skuDetails[0].Offer_price !==
                                        null && "12px",
                                  }}
                                >
                                  ₹
                                  {formatWithCommasWithoutDecimal(
                                    calculateDealerPrice(
                                      parseInt(data.skuDetails[0].MRP_price),
                                      parseInt(data.skuDetails[0].NDP_Price),
                                      data?.GST
                                    )
                                  )}
                                </b>
                              ) : (
                                <b
                                  className="login-to-see-price"
                                  onClick={toggleOverlay}
                                >
                                  Login to see Price
                                </b>
                              )}
                            </span>
                            {Object.keys(userDetail).length > 0 &&
                              data?.skuDetails[0].Offer_price !== null && (
                                <span className="products-list-card-product-offer-price">
                                  ₹
                                  {formatWithCommasWithoutDecimal(
                                    calculateOfferPrice(
                                      parseInt(data.skuDetails[0].MRP_price),
                                      parseInt(data.skuDetails[0].Offer_price),
                                      data?.GST
                                    )
                                  )}
                                </span>
                              )}
                          </>
                        )}
                    </div>
                    <div className="products-list-card-product-original-price">
                      {data &&
                        data?.skuDetails &&
                        data?.skuDetails?.length > 0 &&
                        data?.skuDetails[0]?.MRP_price && (
                          <span className="products-list-card-product-original-price-span">
                            Product MRP - ₹
                            {formatWithCommasWithoutDecimal(
                              parseInt(data.skuDetails[0].MRP_price)
                            )}
                          </span>
                        )}
                    </div>
                    {calculateDiscountPercentage(
                      data?.skuDetails[0]?.MRP_price,
                      data?.skuDetails[0]?.NDP_Price
                    ) > 0 && (
                      <div className="product-list-card-product-dealer-margin-ribbon">
                        {Object.keys(userDetail).length > 0 ? (
                          <>
                            <LocalOfferOutlinedIcon />{" "}
                            <span
                              style={{
                                textDecoration:
                                  data?.skuDetails[0].Offer_price !== null &&
                                  "line-through",
                                fontSize:
                                  data?.skuDetails[0].Offer_price !== null &&
                                  "8px",
                                margin: "0 0.2vw",
                              }}
                            >
                              {" "}
                              {calculateDiscountPercentage(
                                data?.skuDetails[0]?.MRP_price,
                                calculateDealerPrice(
                                  parseInt(data.skuDetails[0].MRP_price),
                                  parseInt(data.skuDetails[0].NDP_Price),
                                  data?.GST
                                )
                              )}
                              %
                            </span>
                            {data?.skuDetails[0].Offer_price !== null && (
                              <span className="product-list-card-product-dealer-margin-offer-ribbon">
                                {calculateDiscountPercentage(
                                  data?.skuDetails[0]?.MRP_price,
                                  calculateOfferPrice(
                                    parseInt(data.skuDetails[0].MRP_price),
                                    parseInt(data.skuDetails[0].Offer_price),
                                    data?.GST
                                  )
                                )}
                                %
                              </span>
                            )}{" "}
                          </>
                        ) : (
                          <div className="not-visible-container">
                            <VisibilityOffOutlinedIcon
                              onClick={toggleOverlay}
                            />{" "}
                            <span>%</span>
                          </div>
                        )}{" "}
                        DEALER MARGIN
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* </Carousel> */}
        {openDesktopVariant !== null && (
          <SelectDesktopVariant
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            openDesktopVariant={openDesktopVariant}
            setOpenDesktopVariant={setOpenDesktopVariant}
            product={products?.product.find(
              (item) => item.prod_ID === openDesktopVariant
            )}
            qtys={qtys}
          />
        )}
      </div>
      <div className="products-main-paginations-container">
        {currentItems && currentItems.length > 0 && (
          <ListingPagination
            totalItems={items && items.length}
            itemsPerPage={itemsPerPage}
            onPageChange={onPageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      {isOverlayVisible && (
        <div className="overlay-container">
          <AuthenticationPage />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
